/* Main Styles */
.iffra-plugins-products-container {
  border-radius: 20px 0px 0px 20px;
  background: #fff;
  min-height: 100vh;
}
.iffra-plugins-products-container-wrap {
  border-radius: 10px;
  border: 1px solid #dee2f1;
}
.iffra-plugins-page-title {
  color: #1f2a49;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  border-bottom: 1px solid #dee2f1;
  padding-bottom: 6px;
}

/* Header Styles */
.iffra-plugins-main-title {
  color: #394b84;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  margin: 0 auto;
}

.iffra-plugins-main-description {
  color: #000;
  font-family: Poppins;
  font-size: var(--text-size);
  font-style: normal;
  font-weight: var(--text-font-weight);
  margin: 0 auto;
}

/* Product Card Styles */
.iffra-plugins-product-card {
  background: #ffffff;
  border-radius: 12px;
  border: 1px solid #dee2f1;
  background: #fff;
  height: 100%;
  transition: transform 0.2s ease;
}

.iffra-plugins-product-card:hover {
  transform: translateY(-5px);
  border-radius: 8px;
  background: #fff;
  border: 1px solid #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.13);
}

.iffra-plugins-product-icon {
  width: 80px;
  height: 60px;
  object-fit: contain;
}

.iffra-plugins-product-title {
  color: #1f2a49;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.iffra-plugins-product-description {
  color: #1f2a49;
  font-size: var(--text-size);
  font-style: normal;
  font-weight: var(--text-font-weight);
}

/* Button Styles */
.iffra-plugins-btn-try-free {
  border-radius: 4px;
  border: 1px solid #394b84;
  background: #394b84;

  padding: 12px;
  color: #fff;
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.iffra-plugins-btn-try-free:hover {
  color: #2d4373;
  background-color: white;
}

.iffra-plugins-btn-coming-soon {
  background-color: white;
  padding: 12px;
  border-radius: 4px;
  color: #2d4373;
  font-size: var(--text-size);
  font-style: normal;
  font-weight: 500;
  border: 1px solid #2d4373;
}
/* Responsive Adjustments */
@media (max-width: 768px) {
  .iffra-plugins-main-title {
    font-size: 2rem;
  }

  .iffra-plugins-main-description {
    font-size: 1rem;
    padding: 0 15px;
  }

  .iffra-plugins-product-card {
    margin-bottom: 20px;
  }
}

@media (max-width: 576px) {
  .iffra-plugins-main-title {
    font-size: 1-75rem;
  }

  .iffra-plugins-product-title {
    font-size: 1.1rem;
  }
}
