.create-task-modal-main {
  /* width: 100%; */
  max-width: 750px !important;
  justify-content: center;
}

.attachment-task-modal-main {
  width: 100%;
  max-width: 752px !important;
}

.create-task-modal-main .modal-content {
  border-radius: 24px !important;
  width: 690px;
}

.create-task-modal-main-width .modal-content {
  width: 750px;
}

.attachment-task-modal-main .modal-content {
  border-radius: 24px !important;
  width: 752px;
}

.create-task-modal-main .modal-body {
  padding: 20px 26px;
}

.attachment-task-modal-main .modal-body {
  padding: 20px 26px;
}

.create-task-modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 26px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
}

.create-task-modal-header p {
  font-size: var(--heading-size);
  width: var(--heading-weight);
  color: var(--font-color);
}

.create-task-modal-header p span {
  font-weight: var(--text-font-weight);
}

.create-task-modal-header button {
  border: 1px solid var(--divider-color);
  color: var(--font-color);
  background: var(--search-bar-bg);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.custom-modal-close-btn {
  border: 1px solid var(--divider-color);
  color: var(--font-color);
  background: var(--search-bar-bg);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}
.remove-uploaded-file-button {
  border: 1px solid rgba(0, 0, 0, 0.07);
  background: #fff;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  flex-shrink: 0;
}

.create-new-task-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.create-new-task-form .form-group label {
  font-size: var(--text-size);
  font-weight: var(--heading-weight);
  margin-bottom: 8px;
  display: flex;
  color: var(--font-color);
  align-items: center;
}
.create-new-task-form .form-group .form-control::placeholder {
  color: var(--font-color);
  opacity: 0.5;
}

.create-new-task-form .form-group .form-control {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  border: 1px solid var(--divider-color);
  background-color: var(--card-color);
  color: var(--font-color);
  height: 40px;
}

.create-new-task-form .form-group .css-13cymwt-control {
  border: 1px solid #e5ebff;
  min-height: 40px !important;
  border-radius: 6px;
  font-size: 13px !important;
  font-weight: 300;
}

.create-new-task-form .assignee-group .css-t3ipsp-control {
  max-height: 40px !important;
  /* padding-left: 0px !important; */
  min-height: 40px !important;
  font-size: 13px !important;
  font-weight: 300;
}

.create-new-task-form .selected-create-task-field .select__control {
  background: rgba(148, 171, 255, 0.24);
}

.form-group-attachment button {
  padding: 0;
}

.form-group-attachment button svg {
  margin-right: 0 !important;
}

.create-task-upload-file-button {
  width: 32px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--icon-color2);
  border-radius: 6px;
  cursor: pointer;
}

.create-task-upload-file-button svg {
  color: var(--selected-route-color-svg);
}

.create-task-upload-file-button:hover {
  background: var(--icon-color2);
}

.create-task-upload-file-button:hover svg {
  color: #fff;
}

.create-task-modal-uploaded-files {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.file-item-one {
  border-radius: 0.35rem;
  border: 1px solid #e5ebff;
  background: #f8fafb;
  padding: 0px 12px;
  height: 45px;
  font-size: var(--text-size);
  font-weight: 500;
  display: flex;
  color: #1f2a49;
  align-items: center;
  justify-content: space-between;
}

.selector-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 20px; /* gap between items */
  flex-wrap: wrap;
}

.create-task-top-fields {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 items per row */
  gap: 20px; /* gap between items */
  flex-wrap: wrap;
}

.create-task-top-fields-has-assignedto-value {
  grid-template-columns: repeat(5, 1fr);
}

.selector-group > *:first-child {
  grid-column: span 2;
}

.create-task-top-fields > *:first-child {
  grid-column: span 3;
}

.create-task-top-fields > *:last-child {
  grid-column: span 1;
}

.create-task-top-fields-has-assignedto-value > *:first-child {
  grid-column: span 3;
}

.create-task-top-fields-has-assignedto-value > *:last-child {
  grid-column: span 2;
}

.selector-group .select__control,
.create-task-top-fields .select__control {
  border-radius: 6px;
  border: 1px solid var(--divider-color) !important;
  background: #fff;
  font-size: var(--text-size);
  padding: 0px 6px !important;
  max-height: 40px !important;
  min-height: 40px !important;
  height: auto;
  transition: border-color 0.3s, box-shadow 0.3s;
  background: transparent;
  min-width: 90px;
  cursor: pointer;
}

.selector-group .select__control--is-focused,
.create-task-top-fields .select__control--is-focused {
  padding: 0px 6px !important;
}

.selector-group .form-group .select__single-value,
.create-task-top-fields .form-group .select__single-value {
  max-width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.selector-group .assignee-group .select__single-value,
.create-task-top-fields .assignee-group .select__single-value {
  max-width: 100% !important;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
}

.selector-group .select__value-container,
.create-task-top-fields .select__value-container {
  padding: 0;
}

.due-date-icon {
  position: absolute;
  left: 14px;
  top: 50%; /* Start centering vertically */
  transform: translateY(-50%); /* Adjust to center precisely */
  z-index: 1;
}

.due-date-icon svg {
  color: var(--icon-color);
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.create-new-task-deadline {
  position: relative;
}

.create-new-task-deadline .form-control {
  font-size: 13px !important;
  padding-left: 38px;
  /* text-align: center; */
}

.create-task-modal-selected-date .flatpickr-wrapper .form-control {
  background: rgba(148, 171, 255, 0.24);
}

.create-new-task-deadline .flatpickr-months,
.create-new-task-deadline .flatpickr-weekdays,
.create-new-task-deadline .flatpickr-day.selected {
  background: var(--app-color-1);
  color: #fff;
}

.create-new-task-deadline .flatpickr-day.selected {
  border: 1px solid var(--app-color-1);
}

.create-new-task-deadline .flatpickr-monthDropdown-months,
.create-new-task-deadline .cur-year,
.create-new-task-deadline span.flatpickr-weekday {
  color: #fff !important;
}

.create-task-modal-footer {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  gap: 10px !important;
  padding: 20px 26px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.create-task-modal-footer > * {
  margin: 0 !important;
}

.create-task-modal-footer-button-1 {
  border-radius: 6px;
  border: 1px solid var(--icon-color2);
  background: var(--icon-color2);
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: #fff;
  padding: 10px 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.create-task-modal-footer-button-2 {
  border-radius: 6px;
  border: 1px solid #e5ebff;
  background: #e5ebff;
  font-size: var(--text-size);
  font-weight: var(--heading-weight);
  color: var(--app-color-2);
  padding: 6px 20px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.create-task-modal-footer-button-1:hover {
  background: var(--icon-color);
  border: 1px solid var(--icon-color);
}

.create-new-task-form .css-1jqq78o-placeholder {
  font-size: 13px !important;
  font-weight: var(--text-font-weight);
  color: hsl(0, 0%, 50%) !important;
}

.create-task-label {
  color: var(--app-color-1) !important;
  font-weight: var(--text-font-weight) !important;
  cursor: pointer;
  font-size: var(--text-size) !important;
}

.create-task-accordion-button {
  border: none;
  background-color: #fff;
}

.create-task-checklist-count {
  border-radius: 3px;
  background: #fafafa;
  font-size: var(--text-size);
  color: var(--font-color);
  font-weight: var(--text-font-weight);
  padding: 5px;
}

.create-task-subtask-count {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: transparent;
  font-size: var(--text-size);
  color: var(--font-color);
  font-weight: var(--text-font-weight);
  border: 1px solid #cccccc;
  cursor: pointer;
}

.create-task-todo-list-item {
  padding: 8px 12px;
  border-radius: 6px;
  /* border: 1px solid #ddd; */
  background: #fafafa;
}

.create-task-todo-list-item svg {
  color: rgba(0, 0, 0, 0.5);
}

.subtask-input {
  background: transparent !important;
}

.create-task-subtask-count-checked {
  background: var(--app-color-1);
  color: #fff;
  border: 1px solid var(--app-color-1);
}

.create-task-check-txt {
  /* opacity: 0; */
  /* transform: translateX(-50px);
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
    max-width: 0; */
  max-width: 300px; /* Adjust based on content */
  overflow: hidden;
  white-space: nowrap;
  font-weight: var(--text-font-weight);
}

/* .create-task-check-txt.visible {
    opacity: 1;
    transform: translateX(0);
    max-width: 300px;
  }
  
  .create-task-check-txt.hidden {
    opacity: 0;
    transform: translateX(-50px);
    max-width: 0;
  } */

.create-task-open-description {
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid var(--divider-color);
  background: #fff;
  color: rgba(0, 0, 0, 0.5);
  font-size: var(--text-size);
  font-style: normal;
  font-weight: var(--text-font-weight);
  line-height: normal;
  padding: 12px;
}

.form-control-value:not(:focus) {
  border: 1px solid #fff !important;
  font-size: var(--heading-size) !important;
  cursor: pointer;
}

.form-control-value:not(:focus):hover {
  border: 1px solid var(--divider-color) !important;
}

.create-new-task-form .select-item-assigned-to .select__control {
  background: transparent;
  border: none !important;
}

#create-my-tooltip {
  max-width: 300px !important; /* Set the desired width */
  white-space: normal !important; /* Allow wrapping */
}

@media (max-width: 768px) {
  .create-task-modal-main {
    /* width: 100%; */
    max-width: 100% !important;
  }
  .selector-group {
    grid-template-columns: repeat(2, 1fr); /* 4 items per row */
  }
}

@media (max-width: 500px) {
  .selector-group {
    grid-template-columns: repeat(1, 1fr); /* 4 items per row */
  }
}
