.verify-email-main {
    height: 100vh;
    background-image: url("../../assets/bg.png");
    background-size: cover; /* This ensures the image covers the entire container */
    background-position: center; /* This centers the image within the container */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .verify-email-main .card {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  
  .verify-email-main .card,
  .verify-email-main .card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .verify-email-main .card .card-body p {
    text-align: center;
    margin-top: 15px;
  }
  
  .verify-email-main .card img {
    width: 30%;
    margin-top: 15px;
  }
  
  .email-verify-btn {
    width: 60%;
    background: var(--app-color-2);
    outline: none;
    border: none;
    color: #fff;
    height: 50px;
    border-radius: 4px;
    font-weight: bold;
    box-shadow: 0px 6px 20px -6px #8b9bce;
    font-size: var(--text-size);
    margin-top: 15px;
  }
  
  .email-verify-btn:hover {
    background-color: var(--app-color-1);
  }
  
  .btn-group-not-found button {
    align-items: center;
    background: var(--app-color-2);
    border-radius: 6px;
    color: #fff;
    display: flex;
    font-size: 12px;
    font-weight: 700;
    justify-content: center;
    margin-top: 15px;
    max-height: 42px;
    padding: 12px 0;
    text-align: center;
    min-width: 262px;
    border: 1px solid var(--app-color-2);
    font-family: "Roboto", sans-serif;
  }
  
  .btn-group-not-found button:hover {
    border: 1px solid var(--app-color-1);
    background: var(--app-color-1);
  }
  
  @media (max-width: 600px) {
    .page-not-wrapper img {
      width: 90% !important;
    }
  }
  @media (max-width: 320px) {
    .btn-group-not-found button {
      min-width: 98vw;
    }
  }
  