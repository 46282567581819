.userProfileImageHeader {
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.cloud-notification-text {
  margin-bottom: 0px !important;
  font-size: var(--text-size);
  font-family: var(--text-font-family) !important;
}

.header-module-toggle-btn {
  border-radius: 4px;
  border: 1px solid #94abff;
  display: flex;
  align-items: center;
  gap: 4px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}
.module-dropdown-item-img-div {
  width: 85px !important;
}
.module-dropdown-item-img {
  width: 100% !important;
  height: 68px !important;
  object-fit: contain;
}

.header-module-toggle-btn p {
  color: #3a4a82;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.module-dropdown-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.module-dropdown-item-task-title {
  color: #94abff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color) !important;
  background-color: var(--bs-dropdown-link-hover-bg) !important;
}
.dropdown-item{
  text-wrap: wrap;
}
.module-dropdown-item-crm-title {
  color: #0c84eb;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.module-dropdown-item-chat-title {
  color: #946bce;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
}
.module-dropdown-item-descp {
  color: #424242;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  text-wrap: wrap;

}

.cloud-txt-1 b {
  font-weight: 500 !important;
}

.cloud-txt-2 {
  font-weight: var(--text-font-weight) !important;
}

.theme-dropdown-text {
  font-size: var(--text-size) !important;
  font-weight: var(--text-font-weight) !important;
  color: #212529 !important;
}

.settings-header svg {
  color: var(--selected-route-color-svg);
}
.dropdown-menu {
  background-color: var(--search-bar-bg);
}
.dropdown-item {
  color: var(--font-color);
}

.dropend.btn-group button {
  color: var(--font-color) !important;
}
