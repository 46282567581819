.notification-canvas {
  width: 512px !important;
}

.notification-model-feedback-text {
  color: var(--app-color-1);
  font-family: var(--text-font-family);
  font-size: var(--text-size);
  font-style: normal;
  font-weight: var(--text-font-weight);
  line-height: normal;
  letter-spacing: -0.34px;
  margin: 0 !important;
}

.notification-model-notification-title {
  color: var(--font-color);
  font-family: var(--text-font-family);
  font-size: var(--heading-size);
  font-style: normal;
  font-weight: var(--heading-weigth);
  line-height: normal;
  letter-spacing: -0.6px;
  margin-top: 7px;
}

.btn-role-concave {
  position: absolute;
  height: 40px;
  width: 40px;
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
  background-color: var(--icon-color2);
  display: flex;
  align-items: center;
  justify-content: center;
  top: 77px;
  left: -40px;
  color: #fff;
  border: 1px solid var(--icon-color2);
}

.nav-notification-warp {
  padding: 0;
}

.nav-notification-warp ul {
  list-style: none;
  background: #fff;
  margin-bottom: 0 !important;
  position: relative;
  overflow-x: auto;
  overflow-y: visible;
  display: flex;
  flex-wrap: nowrap;
  z-index: 0;
  padding: 0;
}

.nav-notification-warp ul div {
  position: absolute;
  overflow: hidden;
  height: 2px;
  bottom: 0;
  z-index: -1;
  background-color: #eef2ff;
  border-radius: 5px;
}

.nav-notification-warp ul li {
  display: inline-block;

  width: max-content;
}

.nav-notification-warp ul li a {
  display: block;
  padding: 7px 13px;
  color: var(--font-color) !important;
  text-decoration: none;
  font-size: var(--text-size);
  width: max-content;
  font-weight: var(--text-font-weight);
}

.nav-notification-warp ul li a:hover {
  border-bottom: 2px solid var(--app-color-2) !important;
}

.notification-count-span {
  font-weight: var(--heading-weigth);
  font-size: 14px;
  border-radius: 50%;
  background-color: var(--app-color-2);
  width: 22px;
  height: 22px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1; /* Ensures no extra vertical spacing */
  text-align: center; /* Optional for fallback */
  padding: 0; /* Ensures no internal padding */
  box-sizing: border-box; /* Ensures width/height include borders if added later */
}

.notifiy-snackbar {
  text-decoration: none;
}
.profile-image {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-image-large {
  width: 40px !important;
  height: 40px;
  min-width: 40px !important;
  min-height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-dropdown-menu {
  width: 290px;
}

.dropdown-item {
  white-space: normal;
}

.dropdown-item:active {
  background-color: #f8f9fa;
  color: inherit;
}

.dropdown-item.text-danger:hover,
.dropdown-item.text-danger:focus,
.dropdown-item.text-danger:active {
  background-color: #fff5f5;
  color: #dc3545;
}
.profile-dropdown-username {
  color: #12182d;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profile-dropdown-profile {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.profile-dropdown-email {
  color: rgba(18, 24, 45, 0.6);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profile-dropdown-item-icon-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background-color: #f0f3ff;
  color: #94abff;
  border-radius: 50px;
}
.profile-dropdown-item-title {
  color: #12182d;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profile-dropdown-item-descp {
  color: rgba(18, 24, 45, 0.6);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.settings-dropdown-heading {
  color: #12182d;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profile-dropdown-user-image-none {
  width: 35px;
  height: 35px;
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-weight: var(--heading-weigth);
  background: var(--app-color-2);
  padding-top: 8px;
  font-family: var(--text-font-family);
  font-size: var(--text-size);
}

/* Sidebar */
.sidebar-user-profile {
  // margin-bottom: 14px;
  margin-top: 14px;
}

.sidebar-user-profile p {
  font-size: var(--text-size);
  font-weight: var(--text-font-weight);
  color: rgba(18, 24, 45, 0.6);
  line-height: 1;
}

.sidebar-user-profile h4 {
  font-weight: var(--heading-weigth) !important;
  font-size: var(--text-size);
  display: block;
  color: var(--font-color);
}

.sidebar-header-truncate-txt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 155px;
}

@media (max-width: 600px) {
  .notification-canvas {
    width: 85% !important;
  }
}
