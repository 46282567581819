.site-advance-search-popup {
  background: #fff;
  padding: 25px;
  border-radius: 10px;
  width: 60%;
  margin: 30px auto;
}

.popup-heading-quick-result {
}

.popup-heading-quick-result h3 {
  font-weight: 600;
  color: #000;
  font-size: 20px;
}

.popup-heading-quick-result h3 span {
  font-weight: var(--text-font-weight);
}

.popup-heading-quick-result h3 a {
  font-size: var(--text-size);
  color: #94abff !important;
  font-weight: normal;
  margin-left: 15px;
  text-decoration: none !important;
}
.advance-search-date-picker {
  width: 160px !important;
}
.advance-search-date-picker-icon {
  position: absolute;
  /* top: 50%; */
  color: var(--app-color-2);
  right: 10px;
  top: 7px;
  /* Adjust this value to decrease the distance from the input */
  /* transform: translateY(-50%); */
  font-size: var(--text-size);
  /* Adjust the font size of the calendar icon */
  cursor: pointer;
}

.popup-heading-quick-result h3 img {
  width: 15px;
}

.quick-search-list-wrap {
}

.quick-search-list-wrap ul {
  list-style: none;
  text-align: right;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: end;
}

.quick-search-list-wrap ul li {
  /* float: left; */
  width: max-content;
}

.quick-search-list-wrap ul li a {
  background: #e9eeff;
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 12px;
  display: block;
  text-align: center;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  min-width: 73px;
}

.quick-search-list-wrap ul li:not(:first-child) a {
  margin-left: 5px;
}

.selected-options-list-wrap {
  display: flex;
  overflow-x: scroll;
}

.selected-options-list-wrap::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar width */
  height: 0;
  /* Remove scrollbar height */
}

.selected-options-list-wrap div {
  background: #e9eeff;
  padding: 8px 10px;
  border-radius: 6px;
  width: max-content;
  font-size: 12px;
  display: block;
  text-align: center;
  color: #000;
  margin-left: 5px;
  text-decoration: none;
  cursor: pointer;
}

.multiselect-placeholder {
  margin: 0;
  padding: 0;
  color: #999;
}

.selected-options-list-wrap div img {
  width: 12px;
}

.quick-search-list-wrap ul li a img {
  width: 12px;
}

.user-listing-quick-search-list-wrap div a {
  background: #e9eeff;
  padding: 8px 10px;
  border-radius: 6px;
  font-size: 12px;
  display: block;
  text-align: center;
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.user-listing-quick-search-list-wrap div a img {
  width: 12px;
}

.quick-search-list-wrap ul li .form-group {
}

.quick-search-list-wrap ul li .form-group .form-control {
  font-size: 12px;
  border: none;
  background: #e9eeff;
  padding: 8px 10px;
  box-shadow: none;
  outline: none;
  margin-left: 5px;
}

.quick-search-list-wrap {
  position: relative;
  top: -3px;
}

.form-filed-advance-seach-wrap {
  padding: 20px;
  border: 1px solid #e9eeff;
  margin-top: 15px;
  border-radius: 10px;
}

.form-filed-advance-seach-wrap {
}

.form-filed-advance-seach-wrap h4 {
  font-size: 16px;
  color: #000;
  font-weight: 500;
  margin-bottom: 15px;
}

.form-filed-advance-seach-wrap .form-group {
  margin-bottom: 15px;
}

.form-filed-advance-seach-wrap .form-group .form-control {
  height: 45px;
  border: 1px solid #e9eeff;
  box-shadow: none;
  outline: none;
  font-size: 12px;
}

.form-filed-advance-seach-wrap .checkbox {
  border: 1px solid #e9eeff;
  font-size: 13px;
  padding: 8px;
  border-radius: 4px;
  margin-top: 15px;
}

.textarea-group-wrap {
}

.textarea-group-wrap .form-control {
  border: 1px solid #e9eeff;
  box-shadow: none;
  outline: none;
  font-size: 13px;
  padding-top: 15px;
}

.form-filed-advance-seach-wrap .checkbox input {
  position: relative;
  top: 2px;
}

.checkbox-filed-advance-seach-wrap h4 {
  margin-bottom: 0;
}

.btn-searc-result-wraper {
  margin-top: 20px;
}

.btn-searc-result-wraper .more-filter-btn {
}

.btn-searc-result-wraper .more-filter-btn button {
  background: none;
  border: none;
  outline: none;
  color: #394b87;
  font-size: var(--text-size);
  margin-top: 10px;
}

.main-search-btn {
  text-align: right;
}

.main-search-btn button {
  background: var(--app-color-2) !important;
  padding: 12px 45px;
  color: #fff;
  font-size: var(--text-size);
  border: none;
  border-radius: 6px;
}

.main-search-btn button:hover {
  background: var(--app-color-1) !important;
}

.multi-select .dropdown-container {
  text-align: start;
  height: 45px;
  border: 1px solid #e9eeff !important;
  box-shadow: none;
  outline: none !important;
  font-size: 13px !important;
  display: flex !important;
  align-items: center !important;
}

.form-group-advanced-filter {
  position: relative;
}

@media (max-width: 767px) {
  .quick-search-list-wrap ul {
    margin-bottom: 0;
    margin-top: 10px;
  }

  .quick-search-wrap-two ul {
    margin-top: 5px !important;
  }
}

@media (max-width: 550px) {
  .main-search-btn button {
    min-width: 100%;
  }
}

@media (max-width: 381px) {
  .quick-search-list-wrap ul li .form-group .form-control {
    margin-left: 0;
  }
}

@media (max-width: 460px) {
  .quick-search-list-wrap {
    width: 100%;
  }

  .quick-search-list-wrap ul {
    min-width: 100%;
    padding-left: 0;
    justify-content: start;
  }

  .advance-search-date-picker-icon {
    right: 15px;
  }
}
