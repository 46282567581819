/* CSS admin panel */

.dashboard-main-wraper {
    background: var(--background-dashboard-color);
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    transition: background-color var(--transition-duration)
      var(--transition-timing-function);
  }
  
  .dashboard-main-wraper::-webkit-scrollbar,
  .offcanvas-body::-webkit-scrollbar {
    width: 10px;
  }
  
  .dashboard-main-wraper::-webkit-scrollbar-thumb,
  .offcanvas-body::-webkit-scrollbar-thumb {
    background-color: var(--scroll-thumb);
    border-radius: 5px;
  }
  
  .dashboard-main-wraper::-webkit-scrollbar-track,
  .offcanvas-body::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .dashboard-main-wraper ul {
    padding-left: 0;
  }
  
  .dashboard-header {
    padding: 15px 0 4px 0;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    background: var(--background-dashboard-color);
    transition: background-color var(--transition-duration)
      var(--transition-timing-function);
    padding-top: 0;
  }
  
  .dashboard-header-instance {
    margin-top: 110px !important;
  }
  
  // .left-right-main {
  //   background-color: var(--app-color-1) !important;
  // }
  
  .banner-two {
    margin-top: 115px !important;
  }
  
  .banner-one {
    margin-top: 84px !important;
  }
  
  .dashboard-header .container-fluid {
    margin-top: 10px;
  }
  
  .hr-header {
    margin-bottom: 0;
    color: #394b84;
  }
  
  .menu-icon {
    cursor: pointer;
  }
  
  .header-nav-list {
    margin-top: 3px;
  }
  
  .more-img {
    transform: rotate(90deg);
    width: 24px;
    cursor: pointer;
  }
  
  .cross-img {
    width: 24px;
    max-width: 24px;
  }
  
  .header-nav-list ul {
    list-style: none;
    margin-bottom: 0px;
  }
  
  .header-nav-list ul li {
    display: inline-block;
    margin-right: 5px;
    font-family: var(--text-font-family);
  }
  
  .header-nav-list ul li:nth-child(1) {
    position: relative;
    top: -2px;
  }
  
  .header-nav-list ul li span {
    font-weight: var(--heading-weigth);
    color: #000000;
    font-size: var(--text-size);
  }
  
  .logo-dash {
    width: 100px;
    height: 26px;
    object-fit: contain;
    margin-bottom: 5px;
  }
  
  .header-top-right-nav {
    position: relative;
    top: -5px;
  }
  
  .header-top-right-nav ul {
    list-style: none;
    text-align: right;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  
  .header-top-right-nav ul li {
    display: flex;
    margin-left: 24px;
    cursor: pointer;
    align-items: center;
    vertical-align: middle;
    margin-top: 4px;
  }
  
  .header-top-right-nav ul li a {
    text-decoration: none;
    font-size: var(--text-size);
    font-weight: var(--text-font-weight);
  }
  
  .header-top-right-nav ul li:nth-child(1) img {
    width: 26px;
  }
  
  .header-top-right-nav ul li:nth-child(4) img {
    // width: 30px;
  }
  
  .header-top-right-nav ul li:nth-child(6) img {
    width: 30px;
  }
  
  .header-top-right-nav ul li a img {
    width: 22px;
  }
  
  .header-top-right-nav i {
    font-size: 22px;
    color: var(--selected-route-color-svg);
  }
  
  .header-top-right-nav li:nth-child(4) i {
    position: relative !important;
    top: 1px !important;
    font-size: 24px;
  }
  
  .header-top-right-nav li:nth-child(2) i {
    position: relative !important;
    top: 1px !important;
  }
  
  .header-top-right-nav ul li a span {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    color: #fff;
    font-weight: var(--heading-weigth);
    background: var(--app-color-2);
    padding-top: 5px;
    font-family: var(--text-font-family);
    font-size: var(--text-size);
  }
  
  .notification-badge {
    position: absolute !important;
    display: flex !important;
  
    height: auto !important;
    min-width: 14px !important;
    width: auto !important;
    line-height: 13px !important;
    padding: 0px 2px !important;
    font-size: 8px !important;
    font-weight: 100;
    top: -5px;
    left: 15px !important;
    background: #ff2929 !important;
    align-items: center;
    justify-content: center;
  }
  
  .user-role-permission-card-delete-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px !important;
    width: 80px !important;
    background: transparent !important;
    // box-shadow: 3px 3px 3px black;
    transition: opacity 0.3s ease, visibility 0s linear 0.3s,
      width 100s ease-in-out;
  }
  
  .user-role-permission-card-delete-badge {
    opacity: 0;
    display: none;
    visibility: hidden;
    width: 0;
  }
  
  .user-role-permission-card-delete-badge img {
    align-self: center;
  }
  
  .user-role-permission-card-delete-badge-span {
    height: 27px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 3px;
    padding-right: 3px;
    border: 1px solid #f1f4ff;
    border-radius: 2px;
  }
  
  .user-role-permission-card-delete-badge-span:hover {
    background-color: #f1f4ff;
  }
  
  .user-role-permission-card:hover .user-role-permission-card-delete-badge {
    opacity: 1;
    display: flex;
    visibility: visible;
    width: 80px;
    transition-delay: 5s;
  }
  
  .top-mobile-options {
    display: none;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .top-mobile-options ul {
    text-align: start;
    margin-top: 5px;
  }
  
  .left-right-dashboard-wraper {
    margin-top: 50px;
  }
  
  .left-right-dashboard-wraper .container-fluid {
    margin-left: 0;
    padding-left: 0;
    background-color: var(--background-dashboard-color);
    transition: background-color var(--transition-duration)
      var(--transition-timing-function);
  }
  
  .left-nav-wrap {
    width: 260px;
    background: var(--card-color);
    height: 100vh;
    border-radius: 0px 20px 0px 0px;
    padding: 15px 25px 15px 15px;
    position: fixed;
    padding-bottom: 30px;
    transition: width 0.3s ease;
    overflow-x: hidden;
  }
  
  .left-nav-main {
    scrollbar-width: none;
    scroll-behavior: smooth;
    // overflow: auto;
    height: calc(100% - 40px);
  }
  
  .left-nav-main-one {
    height: calc(100% - 60px) !important;
  }
  
  .left-nav-main-two {
    height: calc(100% - 90px) !important;
  }
  
  .invite-select-control {
    overflow: hidden;
  }
  
  .collapse-nav {
    padding: 0px;
    padding-top: 15px;
    width: 50px;
  }
  
  // .ul-collapsed {
  //   padding-left: 1rem !important;
  // }
  
  .left-nav-wrap::-webkit-scrollbar-track,
  .vertical-navbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  .left-nav-wrap::-webkit-scrollbar,
  .vertical-navbar::-webkit-scrollbar {
    width: 0;
    background-color: #f5f5f5;
  }
  
  .left-nav-wrap::-webkit-scrollbar-thumb,
  .vertical-navbar::-webkit-scrollbar-thumb {
    background-color: #666;
  }
  
  .vertical-navbar ul {
    background-color: #e9eeff;
    height: 100%;
    box-shadow: 4px 0 6px rgba(0, 0, 0, 0.1);
    /* Slight shadow on the right */
  }
  
  .left-nav-wrap ul,
  .vertical-navbar ul {
    list-style: none;
  }
  
  .left-nav-wrap ul li,
  .category-li,
  .vertical-navbar ul li {
    font-family: var(--text-font-family);
    border-bottom: 1px solid var(--divider-color);
    line-height: 34px;
    padding-bottom: 5px;
  }
  
  // .category-sub-a {
  //   font-family: "Nunito Sans", sans-serif;
  //   font-weight: var(--heading-weigth);
  
  // }
  .left-nav-wrap ul li a,
  .vertical-navbar ul li a {
    padding-left: 10px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .left-nav-main-collapse ul li {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .left-nav-main-collapse .sidebar-icon-wrap svg {
    margin-left: 0;
    margin-right: 0;
  }
  
  .left-nav-main-collapse ul li a {
    padding-left: 0px;
    width: 35px;
    height: 35px;
    text-align: center;
  }
  
  .left-nav-wrap ul li span,
  .category-li span {
    padding-left: 10px;
  }
  
  .left-nav-wrap ul li a:hover {
    background-color: var(--selected-route-color);
  }
  
  .left-nav-wrap ul li a:hover svg {
    color: var(--selected-route-color-svg) !important;
  }
  
  .left-nav-wrap ul li a,
  .category-li a,
  .vertical-navbar ul li a {
    display: block;
    font-size: var(--text-size);
    color: var(--font-color);
    text-decoration: none;
    font-weight: var(--text-font-weight);
    line-height: 35px;
    position: relative;
  }
  
  // .left-nav-wrap ul li a:hover,
  // .category-li a:hover,
  .vertical-navbar ul li a:hover {
    color: var(--app-color-1) !important;
    cursor: pointer;
  }
  
  .right-menu {
    position: absolute !important;
    right: 0 !important;
    top: 6px !important;
    width: 17px !important;
  }
  
  .left-nav-wrap ul li span,
  .category-li span,
  .vertical-navbar ul li span {
    font-weight: var(--heading-weigth) !important;
    font-size: var(--text-size);
    display: block;
    color: var(--font-color);
    margin-top: 5px;
    text-transform: uppercase;
  }
  
  .left-nav-wrap ul li img,
  .category-li img,
  .vertical-navbar ul li img {
    width: 18px;
    position: relative;
    top: -2px;
    margin-right: 5px;
  }
  
  .vertical-navbar {
    margin-top: 50px !important;
    height: 100%;
    box-shadow: 4px 0 6px -2px rgba(0, 0, 0, 0.1);
  }
  
  .panel-heading .accordion-toggle:after {
    content: "";
    background: url(../../assets/arrow-menu.svg) no-repeat top;
    width: 17px;
    height: 17px;
    position: relative;
    top: 7px;
    background-size: contain;
    float: right;
    color: grey;
  }
  
  .panel-default > .panel-heading {
    background: none;
    border: none;
    padding: 0;
    box-shadow: none;
    display: block;
    font-size: var(--text-size);
    color: #2e3e73;
    text-decoration: none;
    font-weight: var(--heading-weigth);
  }
  
  .panel-default {
    box-shadow: none;
    border: none;
  }
  
  .panel-group {
    margin: 0;
  }
  
  .dashboard-area-wraper {
    margin-left: 270px;
    margin-top: 50px;
    transition: margin-left 0.3s ease;
  }
  
  .dashboard-area-collapse {
    margin-left: 60px;
  }
  
  .graph-revenue-wrap {
    background: #fff;
    padding: 25px;
    margin-bottom: 10px;
    border-radius: 15px;
  }
  
  .graph-revenue-head {
    margin-bottom: 15px;
  }
  
  .graph-revenue-wrap h5 {
    font-weight: var(--heading-weigth);
    color: var(--font-color);
    font-size: var(--heading-size);
  }
  
  .recent-task-head {
    font-weight: var(--heading-weigth);
    color: var(--font-color);
    font-size: var(--heading-size);
    margin-top: 20px;
    margin-bottom: 18px;
  }
  
  .graph-revenue-wrap h5 span {
    font-weight: var(--text-font-weight);
    color: var(--font-color);
  }
  
  .recent-task-head span {
    font-weight: var(--text-font-weight);
    color: var(--font-color);
  }
  
  .graph-wrap img {
    width: 100%;
  }
  
  // .bar-wrap canvas {
  //   height: 270px !important;
  // }
  .graph-wrap {
    height: 230px;
  }
  
  .left-paddding {
    padding-left: 10px;
  }
  
  .no-right-padding {
    padding-right: 0 !important;
  }
  
  .dashboard-area-wraper {
    font-family: var(--text-font-family) !important;
  }
  
  .dasbboard-numbers-wraper ul {
    list-style: none;
    margin-top: 10px;
    margin: 0 -5px;
  }
  
  .dasbboard-numbers-wraper ul li {
    padding: 0 5px;
  }
  
  .dasbboard-numbers-wraper ul li .listing-number-wrap {
    background: #fff;
    border-radius: 10px;
    padding: 20px 20px 15px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .heading-text-wrap {
    width: 70%;
    padding-left: 15px;
    float: left;
  }
  
  .dashboard-tasks-today {
    border-left: 3px solid var(--app-color-1);
  }
  
  .dashboard-tasks-progress {
    border-left: 3px solid #2098d1;
  }
  
  .dashboard-tasks-completed {
    border-left: 3px solid #2dbf64;
  }
  
  .dashboard-tasks-today span {
    color: var(--app-color-2);
  }
  
  .dashboard-tasks-progress span {
    color: #2098d1;
  }
  
  .dashboard-tasks-completed span {
    color: #2dbf64;
  }
  
  .dashboard-tasks-stat-heading h6 {
    font-size: 16px;
    color: var(--app-color-2);
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  .dashboard-tasks-stat-heading span {
    // position: absolute;
    // bottom: 0;
    line-height: 1;
    font-size: 42px;
    font-weight: var(--heading-weigth);
    font-family: var(--text-font-family) !important;
  }
  
  .number-icon {
    width: 60px;
    height: 60px;
    border-radius: 10px;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
    // text-align: center;
    // padding-top: 11px;
  }
  
  .number-icon svg {
    // color: var(--app-color-1) !important;
    width: 30px;
    height: 30px;
  }
  
  .list-stat-today,
  .list-stat-progress,
  .list-stat-completed {
    cursor: pointer;
  }
  
  .list-stat-today .number-icon {
    background-color: #e9eeff !important;
  }
  
  .list-stat-today .number-icon svg {
    color: var(--app-color-1) !important;
  }
  
  .list-stat-progress .number-icon {
    background-color: #dbeff8 !important;
  }
  
  .list-stat-progress .number-icon svg {
    color: #4cabda !important;
  }
  
  .list-stat-completed .number-icon {
    background-color: #ddf5e6 !important;
  }
  
  .list-stat-completed .number-icon svg {
    color: #4bc87a !important;
  }
  
  .listing-number-wrap p {
    font-size: var(--text-size);
    color: var(--font-color);
    padding-top: 8px;
    margin-bottom: 0;
    font-weight: var(--text-font-weight);
  }
  
  .team-member-table-wrap {
    // background: #fff;
    // padding: 25px;
    // margin-bottom: 25px;
    border-radius: 15px;
    margin-top: 25px;
  }
  
  .team-member-table-wrap h3 {
    font-weight: var(--heading-weigth);
    color: var(--font-color);
    margin-bottom: 15px;
    font-size: var(--heading-size);
  }
  
  .team-member-table-wrap h3 span {
    font-weight: var(--text-font-weight);
    color: var(--font-color);
  }
  
  .team-member-table-wrap .table-responsive {
    margin: 0;
  }
  
  .team-member-table-wrap .table-responsive .table {
    border: 1px solid #f1f4ff;
    margin-bottom: 0;
    min-width: 515px;
  }
  
  .team-member-table-wrap .table-responsive .table tr td {
    border: 1px solid #f1f4ff;
    font-size: var(--text-size);
    color: var(--font-color);
    vertical-align: middle;
    padding: 0px 15px;
    font-weight: var(--text-font-weight);
  }
  
  .heading-table-wrap td {
    font-size: var(--text-size) !important;
    color: var(--font-color) !important;
    padding: 10px 15px;
  }
  
  .email-table a {
    text-decoration: none;
    color: var(--font-color);
    border-bottom: 1px solid var(--app-color-2);
  }
  
  .email-table,
  .salary-wrap,
  .btn-action {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  
  .salary-wrap {
    color: var(--app-color-1);
  }
  
  .progress {
    padding: 0 !important;
    margin-bottom: 0;
    height: 10px !important;
    overflow: hidden;
    background-color: #f5f5f5 !important;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1) !important;
  }
  
  .btn-action a {
    display: inline-block;
    width: 32px;
    height: 30px;
    border: 1px solid #f1f4ff;
    text-align: center;
    padding-top: 4px;
  }
  
  .btn-action a img {
    width: 18px;
  }
  
  .btn-action a:hover {
    background: #e9eeff;
  }
  
  .progress-bar-custom {
    background-color: var(--app-color-2);
    height: 10px;
  }
  
  .profile-main-wraper {
    background: var(--card-color);
    padding: 32px 25px;
    margin-bottom: 10px;
    border-radius: 15px;
    text-align: center;
  }
  
  .no-left-paddding {
    padding-left: 0 !important;
    margin-left: 0px;
    padding-right: 4px !important;
  }
  
  .profile-image-wraper {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    background-size: cover !important;
    object-fit: cover;
  }
  
  .profiel-text-wrap {
    text-align: center;
    margin-top: 15px;
  }
  
  .profiel-text-wrap h4 {
    font-weight: var(--heading-weigth);
    font-size: var(--heading-size);
    color: var(--font-color);
    margin-bottom: 0;
  }
  
  .profiel-text-wrap span {
    color: #828282;
    font-size: var(--text-size);
  }
  
  .btn-profile-wrap {
    margin-top: 15px;
  }
  
  .btn-profile-wrap .profile-dash-btn {
    display: inline-block;
    width: 78%;
    border: 1px solid var(--exotic-border-color);
    padding: 12px 0;
    color: var(--exotic-font-color);
    font-weight: var(--text-font-weight);
    font-size: var(--text-size);
    border-radius: 10px;
    text-decoration: none;
    background-color: var(--btn-transparent);
  }
  
  .btn-profile-wrap .profile-dash-btn:hover {
    background-color: var(--app-color-2);
    color: #fff;
  }
  
  .btn-profile-wrap .edit-btn {
    display: inline-block;
    width: 20%;
    border: 1px solid #ccc;
    padding: 12px 0;
    color: var(--font-color);
    font-weight: var(--text-font-weight);
    font-size: var(--text-size);
    border-radius: 10px;
    text-decoration: none;
  }
  
  .edit-btn {
    width: 20%;
    background: var(--app-color-2);
    border: 1px solid var(--app-color-2) !important;
    cursor: pointer;
  }
  
  .edit-btn:hover {
    background: var(--app-color-1);
    border: 1px solid var(--app-color-1) !important;
  }
  
  .edit-btn img {
    width: 18px;
    position: relative;
    top: 0;
  }
  
  .invite-team--member-wraper {
    background: var(--exotic-card-color);
    padding: 25px;
    margin-bottom: 10px;
    border-radius: 15px;
    text-align: center;
  }
  
  .invite-team--member-wraper h4 {
    font-size: var(--heading-size);
    color: #fff;
    margin-bottom: 0;
  }
  
  .invite-team--member-wraper p {
    font-size: var(--text-size);
    color: #fff;
    padding: 5px 0;
  }
  
  .invite-btn-wrap {
    margin-top: 15px;
  }
  
  .invite-btn-wrap a {
    background: var(--exotic-btn-color);
    padding: 16px 0;
    display: block;
    text-align: center;
    color: #fff !important;
    font-weight: var(--heading-weigth);
    font-size: 13px;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;
  }
  
  .invite-btn-wrap a img {
    width: 18px;
  }
  
  .recent-task-wrap h4 {
    font-weight: var(--heading-weigth);
    font-size: var(--heading-size);
    margin: 15px 0 10px 0;
    color: var(--font-color);
  }
  
  .recent-task-wrap ul {
    list-style: none;
  }
  
  .recent-task-wrap ul li {
    background: var(--card-color);
    padding: 15px;
    border-radius: 8px;
    margin-top: 10px;
  }
  
  .listing-recent-text {
    width: 60%;
    float: left;
  }
  
  .listing-recent-text h5 {
    font-size: var(--text-size);
    color: #000000;
    margin-bottom: 5px;
    font-weight: var(--text-font-weight);
  }
  
  .listing-recent-text .image-recent-wrap {
    width: 100px;
  }
  
  .button-recent-wrap {
    width: 40%;
    float: left;
  }
  
  .button-recent-wrap {
    margin-top: 10px;
  }
  
  .button-recent-wrap a {
    display: block;
    border: 1px solid var(--app-color-2);
    text-align: center;
    color: var(--app-color-2);
    font-size: 12px;
    padding: 10px 0 8px 0;
    border-radius: 6px;
    text-decoration: none;
  }
  
  .button-recent-wrap a:hover {
    background: var(--app-color-2);
    color: #fff;
  }
  
  /* Sub menu */
  .submenu {
    max-height: 0;
    padding: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    padding-left: 12px;
  }
  
  .submenu-collapse {
    max-height: 0;
    padding: 0px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  .submenu.open {
    max-height: 100px;
    /* Adjust the max height as needed */
    background-color: #f4f6ff;
    border-radius: 6px;
    padding-left: 12px;
    //   padding-top: 10px;
    //   padding-bottom: 10px;
  }
  
  .submenu-collapse.open-collapse {
    max-height: 100px;
    /* Adjust the max height as needed */
    border-radius: 6px;
  }
  
  /* You can add additional styles for your links if needed */
  .submenu a,
  .submenu-collapse a {
    display: block;
    color: var(--font-color);
    /* Adjust the text color as needed */
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }
  
  .submenu a:hover,
  .submenu-collapse a:hover {
    color: #007bff;
    /* Adjust the hover color as needed */
  }
  
  .doghnut-wrapper {
    position: relative;
  }
  
  .doghnut-wrapper canvas {
    width: 200px;
    height: 200px;
  }
  
  .ul-doghnut li {
    font-size: var(--text-size);
    margin-left: 50px;
  }
  
  .inner-doghnut {
    width: 50%;
    height: 50%;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    background-color: #edf1ff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 25px;
    font-weight: var(--heading-weigth);
    color: var(--app-color-2);
    box-shadow: 10px 10px 20px -20px rgba(0, 0, 0, 0.8);
  }
  
  .doghnut-text h4 {
    font-weight: var(--heading-weigth);
    font-size: var(--text-size);
    color: var(--font-color);
    margin-bottom: 14px;
  }
  
  .vertical-navbar {
    display: none;
    position: fixed;
    top: -2px;
    left: -230px;
    height: 100%;
    width: 220px;
    overflow: auto;
    background-color: #fff;
    /* Set your desired background color */
    transition: left 0.3s ease-in-out !important;
    margin-top: 50px;
    z-index: 1;
  }
  
  .vertical-navbar ul {
    list-style: none;
    padding: 20px;
    margin: 0;
    padding-bottom: 35px;
  }
  
  .vertical-navbar ul li {
    margin-bottom: 10px;
  }
  
  .vertical-navbar.closed {
    transform: translateX(100%);
  }
  
  .vertical-navbar.open {
    left: 0;
    /* Bring the navbar to the left when open */
    z-index: 9999;
  }
  
  .mobile-options {
    display: none;
  }
  
  .profile-nav-btn {
    cursor: pointer;
  }
  
  .off-concave-one {
    z-index: 1111 !important;
  }
  
  .selected-route {
    background-color: var(--selected-route-color);
    // color: var(--app-color-1) !important;
    // font-size: 16px !important;
    // transition: font-size 0.3s ease-in-out;
  }
  
  .selected-route svg {
    color: var(--selected-route-color-svg) !important;
  }
  
  .custom-button-main {
    margin-left: 4px;
    height: 35px;
    border: 1px solid var(--icon-color);
    background: none;
    padding: 0 25px;
    border-radius: 6px;
    font-weight: var(--heading-weigth);
    color: var(--exotic-font-color);
    font-size: var(--text-size);
    white-space: nowrap;
  }
  .custom-button-main svg {
    color: var(--selected-route-color-svg);
  }
  
  .button-icon {
    color: var(--icon-color);
    margin-right: 5px;
  }
  
  .custom-button-main:hover {
    background-color: var(--icon-color2);
    border: 1px solid var(--icon-color2);
    color: #fff;
  }
  
  .custom-button-main:hover .button-icon {
    color: #fff;
  }
  
  .custom-button-main:hover svg {
    color: #fff;
  }
  
  .sidebar-icon-wrap svg {
    width: 18px;
    height: 18px;
    color: var(--icon-color);
    margin-right: 5px;
    margin-bottom: 5px;
  }
  
  .email-verify-anchor {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
    color: var(--app-color-1);
  }
  
  .add-on-modal-info {
    font-size: var(--text-size);
    color: var(--app-color-2);
  }
  
  .invite-team--member-image img {
    width: 170px;
  }
  
  .custom-border-btn {
    border: 1px solid #e9eeff !important;
  }
  
  .dashboard-table {
    border-radius: 15px;
  }
  
  .no-dashboard-task-data {
    background-color: var(--card-color);
    border-radius: 15px;
    padding: 80px 0px;
  }
  
  .no-dashboard-task-data img {
    width: 175px;
    height: 150px;
    margin-bottom: 24px;
  }
  
  .no-dashboard-task-data p {
    font-weight: var(--text-font-weight);
    margin-left: 50px;
  }
  
  .dashboard-notification-list {
    border-radius: 15px;
    overflow: hidden;
  }
  
  .dashboard-notification-list ul {
    display: grid;
    grid-auto-rows: 1fr;
    /* Makes each row (each <li>) the same height */
  }
  
  .dashboard-notification-list li {
    border-radius: 0px !important;
    display: flex;
    align-items: center;
    cursor: auto !important;
  }
  
  .dashboard-no-notification {
    background: var(--card-color);
    border-radius: 15px;
    padding: 100px 0px;
  }
  
  .dashboard-no-notification img {
    width: 60px;
    height: 70px;
    margin-bottom: 24px;
  }
  
  .dashboard-no-notification p {
    font-weight: var(--text-font-weight);
  }
  
  .dashboard-table-shimmer {
    border-radius: 15px;
  }
  
  .doghnut-lagend-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333;
    margin-bottom: 10px;
    font-size: 13px;
  }
  
  .doghnut-span {
    float: right;
    color: var(--app-color-1);
  }
  
  /* CSS admin panel */
  @media (max-width: 2100px) and (min-width: 1700px) {
    .graph-wrap {
      height: 230px;
    }
  }
  
  @media (max-width: 1520px) and (min-width: 1170px) {
    .btn-action a {
      width: 30px;
    }
  }
  
  @media (max-width: 1370px) and (min-width: 1170px) {
    .btn-action a {
      width: 28px;
    }
  
    .listing-recent-text h5 {
      font-size: 12px;
    }
  
    .invite-team--member-wraper {
      padding: 12px;
    }
  }
  
  @media (max-width: 1370px) and (min-width: 1170px) {
    .form-section-wrap {
      padding: 110px 50px 0 50px;
    }
  
    .slider-signup-text-wrap p {
      padding: 0 15px;
    }
  
    .image-slider-wrap {
      margin: 20px auto;
    }
  
    .slider-signup-text-wrap h2 {
      font-size: 30px;
    }
  
    .form-section-wrap h3 {
      font-size: 30px;
    }
  }
  
  @media (max-width: 1320px) and (min-width: 1170px) {
    .btn-action a {
      width: 25px;
    }
  }
  
  @media (max-width: 1300px) and (min-width: 1170px) {
    .profile-main-wraper {
      padding: 15px;
    }
  
    .button-recent-wrap a {
      padding: 8px 0 5px 0;
    }
  
    .btn-action a {
      width: 22px;
    }
  
    .listing-recent-text {
      width: 65%;
    }
  
    .button-recent-wrap {
      width: 34%;
    }
  
    .button-recent-wrap a {
      border: none;
      padding: 0;
    }
  
    .button-recent-wrap {
      margin-top: 20px;
    }
  }
  
  .menu-icon-2 {
    display: none;
    cursor: pointer;
  }
  
  @media (max-width: 1135px) {
    .left-nav-wrap {
      display: none;
    }
  
    .dashboard-area-wraper {
      margin-left: 10px;
    }
  
    .menu-icon {
      display: none;
    }
  
    .menu-icon-2 {
      display: block;
    }
  
    .vertical-navbar {
      display: block;
    }
  }
  
  @media (max-width: 1600px) {
    .doghnut-wrapper canvas {
      width: 170px !important;
      height: 170px !important;
    }
  }
  
  @media (max-width: 1460px) {
    .tab-width {
      width: 50% !important;
    }
  }
  
  @media (max-width: 1370px) {
    .doghnut-width {
      width: 50% !important;
    }
  
    .doghnut-wrapper canvas {
      width: 150px !important;
      height: 150px !important;
    }
  }
  
  @media (max-width: 1100px) {
    .profile-main-wraper {
      padding: 32px 10px;
    }
  
    .invite-team--member-wraper {
      padding: 25px 10px;
    }
  }
  
  @media (max-width: 1000px) {
    .dasbboard-numbers-wraper ul li {
      min-width: 100% !important;
    }
  
    .dasbboard-numbers-wraper ul li:nth-child(2) {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  
    .graph-col,
    .graph-col-2 {
      width: 100% !important;
    }
  
    .graph-col {
      padding-right: 0.8rem !important;
    }
  }
  
  @media (max-width: 954px) {
    .btn-profile-wrap {
      display: flex;
      flex-direction: column;
    }
  
    .btn-profile-wrap .profile-dash-btn {
      min-width: 100%;
    }
  
    .btn-profile-wrap .edit-btn {
      min-width: 100%;
      margin-top: 5px;
    }
  
    .recent-task-wrap ul li {
      padding: 15px 6px;
    }
  }
  
  @media (max-width: 1334px) {
    .btn-profile-wrap {
      display: flex;
      flex-direction: column;
    }
  
    .btn-profile-wrap .profile-dash-btn {
      min-width: 100%;
    }
  
    .btn-profile-wrap .edit-btn {
      min-width: 100%;
      margin-top: 5px;
    }
  }
  
  @media (max-width: 840px) {
    .top-left-col {
      width: 100% !important;
      display: flex;
      justify-content: space-between;
    }
  
    .top-right-col {
      display: none;
    }
  
    .top-mobile-options {
      display: block;
      opacity: 1;
    }
  
    .mobile-options {
      display: block;
    }
  }
  
  @media (max-width: 767px) {
    .no-left-paddding {
      padding-right: 7px !important;
    }
  
    .no-right-padding,
    .left-paddding {
      padding-right: 12px !important;
    }
  
    .dash-mobile {
      padding-right: 12px !important;
    }
  
    .dasbboard-numbers-wraper ul li {
      padding-right: 7px !important;
    }
  
    .dasbboard-numbers-wraper ul li .listing-number-wrap {
      margin-bottom: 15px;
    }
  
    .profile-main-wraper,
    .invite-team--member-wraper,
    .recent-task-wrap {
      margin-left: 12px;
    }
  
    .revenue-wrap-pad {
      padding-bottom: 50px;
    }
  
    .dasbboard-numbers-wraper {
      margin-top: 15px;
    }
  
    .tab-width {
      width: 100% !important;
    }
  
    .doghnut-width {
      width: 100% !important;
    }
  
    .doghnut-wrapper canvas {
      width: 200px !important;
      height: 200px !important;
    }
  
    .graph-wrap {
      height: 290px;
    }
  
    .btn-profile-wrap {
      flex-direction: row;
    }
  
    .btn-profile-wrap a {
      min-width: 78% !important;
    }
  
    // .btn-profile-wrap a:last-child {
    //   min-width: 20% !important;
    //   margin-top: 0px;
    //   margin-left: 10px;
    // }
  }
  
  @media (max-width: 413px) {
    .banner-one {
      margin-top: 98px !important;
    }
  
    .banner-two {
      margin-top: 150px !important;
    }
  }
  
  @media (max-width: 500px) {
    .customer-heading-text {
      display: none !important;
    }
  
    .bar-menu-wrap {
      padding: 25px 1px !important;
    }
  
    .bar-menu-wrap h5 {
      margin: 0px 24px;
    }
  }
  
  @media (max-width: 506px) {
    .profile-custom-button-main {
      width: 100% !important;
    }
  }
  
  .assgined-boxes-main-wrp {
    margin-bottom: 12px;
  }
  
  .assgined-boxes-main-wrp .assigned-me-box {
    background: var(--card-color);
    padding: 20px;
    border-radius: 12px;
    height: 100%;
  }
  
  .assgined-boxes-main-wrp .first-md-6 {
    padding-right: 0;
  }
  
  .assigned-me-box h5 {
    font-weight: var(--heading-weigth);
    color: var(--font-color);
    margin-bottom: 25px;
    font-size: var(--heading-size);
  }
  
  .assigned-me-box h5 span {
    font-weight: var(--text-font-weight);
    color: var(--font-color);
  }
  
  .assigned-me-box ul {
    list-style: none;
    margin: 0 -15px;
    padding: 0;
  }
  
  .assigned-me-box ul li {
  }
  
  .assigned-me-box ul li .assgine-me-list {
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    position: relative;
    cursor: pointer;
  }
  
  .assigned-me-box ul li:nth-child(1) .assgine-me-list:before {
    content: "";
    position: absolute;
    height: 55px;
    background: var(--border-color);
    width: 1px;
    right: -10px;
    top: -5px;
  }
  
  .assigned-me-box ul li:nth-child(3) .assgine-me-list:before {
    content: "";
    position: absolute;
    height: 55px;
    background: var(--border-color);
    width: 1px;
    right: -10px;
    top: -5px;
  }
  
  .assgine-me-list .assgine-me-icon {
    width: 50px;
    float: left;
  }
  
  .assgine-me-list .assgine-me-icon img {
    width: 100%;
  }
  
  .assgine-me-list .assgine-me-text {
    width: 72%;
    float: left;
    padding-left: 15px;
    line-height: 24px;
    margin-top: -1px;
  }
  
  .assgine-me-text span {
    display: block;
    font-size: 16px;
    color: var(--exotic-font-color);
  }
  
  .assgine-me-text strong {
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #ff3636;
  }
  
  .assigned-me-box ul li:nth-child(2) strong {
    color: #2098d1;
  }
  
  .assigned-me-box ul li:nth-child(3) strong {
    color: #eba900;
  }
  
  .assigned-me-box ul li:nth-child(4) strong {
    color: #e8910d;
  }
  
  .assigned-me-box ul li:nth-child(3) .assgine-me-list {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
  
  .assigned-me-box ul li:nth-child(4) .assgine-me-list {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none;
  }
  
  .observer-main-wrap {
    background: var(--card-color);
    padding: 20px;
    border-radius: 12px;
  }
  
  .observer-main-wrap h4 {
    font-weight: var(--heading-weigth);
    color: var(--font-color);
    margin-bottom: 25px;
    font-size: var(--heading-size);
  }
  
  .observer-main-wrap h4 span {
    font-weight: var(--text-font-weight);
    color: var(--font-color);
  }
  
  .observer-main-wrap ul {
    list-style: none;
    margin: 0 -15px;
  }
  
  .observer-main-wrap ul li {
    padding-right: 25px;
  }
  
  .observer-main-wrap ul li .listing-observer-wrap {
    border-right: 1px solid var(--border-color);
    cursor: pointer;
  }
  
  .observer-main-wrap ul li:nth-child(4) .listing-observer-wrap {
    border-right: 0;
  }
  
  .listing-observer-wrap .listing-observer-image {
    width: 50px;
    float: left;
  }
  
  .listing-observer-wrap .listing-observer-image img {
    width: 100%;
  }
  
  .listing-observer-text {
    width: 72%;
    float: left;
    padding-left: 15px;
    line-height: 24px;
    margin-top: -1px;
  }
  
  .listing-observer-text span {
    display: block;
    font-size: 16px;
    color: var(--exotic-font-color);
  }
  
  .listing-observer-text strong {
    display: block;
    font-size: 24px;
    font-weight: 600;
    color: #ff3636;
  }
  
  .observer-main-wrap ul li:nth-child(2) strong {
    color: #2098d1;
  }
  
  .observer-main-wrap ul li:nth-child(3) strong {
    color: #eba900;
  }
  
  .observer-main-wrap ul li:nth-child(4) strong {
    color: #e8910d;
  }
  
  .performance-main-wraper {
    background: var(--card-color);
    padding: 20px;
    border-radius: 12px;
    margin-top: 12px;
    position: relative;
  }
  
  .performance-main-wraper h2 {
    font-weight: var(--heading-weigth);
    color: var(--font-color);
    margin-bottom: 0;
    font-size: var(--heading-size);
  }
  
  .performance-main-wraper h2 span {
    font-weight: var(--text-font-weight);
    color: var(--font-color);
  }
  
  .performance-main-wraper h2 strong {
    font-size: 18px;
    color: #94abff;
    font-weight: 600;
    margin: 0 10px;
  }
  
  .performance-main-wraper h2 img {
    width: 11px;
    position: relative;
    top: -1px;
  }
  
  // .performance-main-wraper .dropdown-date {
  //   position: absolute;
  //   top: 20px;
  //   right: 20px;
  // }
  
  .performance-main-wraper
    .dropdown-date
    .basic-single.task-filter-select
    .select__control {
    min-height: 26px !important;
    font-weight: 300 !important;
  }
  
  .team-member-table-wrap {
    background: var(--card-color);
    padding: 20px;
    border-radius: 12px;
    margin-top: 12px;
    position: relative;
  }
  
  .__floater {
    z-index: 2000;
  }
  
  /* Joy ride */
  
  .tour-custom-tooltip .introjs-bullets {
    display: none;
  }
  
  .tour-custom-tooltip .introjs-tooltiptext {
    font-size: var(--text-size);
    font-weight: var(--text-font-weight);
    font-family: "Poppins", sans-serif;
    padding: 15px;
  }
  
  .tour-custom-tooltip .introjs-nextbutton {
    align-items: center;
    background: #394b87;
    border: none;
    border-radius: 4px;
    color: #fff;
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    font-weight: 300;
    justify-content: center;
    min-width: 80px;
    outline: none;
    padding: 5px 12px;
    position: relative;
    font-family: "Poppins", sans-serif;
    text-shadow: none;
  }
  
  .tour-custom-tooltip .introjs-prevbutton {
    align-items: center;
    background: #e9eeff !important;
    color: #394b87 !important;
    border: none;
    border-radius: 4px;
    display: flex;
    flex-wrap: nowrap;
    font-size: 12px;
    font-weight: 300;
    justify-content: center;
    min-width: 80px;
    outline: none;
    padding: 5px 12px;
    position: relative;
    font-family: "Poppins", sans-serif;
    margin-right: 20px;
    cursor: pointer !important;
  }
  
  .tour-custom-tooltip .introjs-button {
    cursor: pointer !important;
  }
  
  .tour-custom-tooltip .introjs-tooltipbuttons {
    display: flex;
    justify-content: end;
    border-top: none;
    padding: 10px 15px 15px 15px;
  }
  
  .tour-custom-tooltip {
    min-width: 320px !important;
    max-width: 320px !important;
    border-radius: 10px !important;
  }
  
  .tour-custom-tooltip .introjs-tooltip-header {
    display: none;
  }
  
  .introjs-button:active {
    background-color: #394b87;
    color: #fff;
  }
  
  .tour-custom-tooltip .introjs-button:hover {
    background-color: #94abff;
    color: #fff;
  }
  
  .tour-custom-tooltip .introjs-button:focus {
    box-shadow: none;
    border: none;
    background-color: #394b87;
    color: #fff;
  }
  
  .introjs-fixedTooltip,
  .introjs-helperLayer {
    box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 0px,
      rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  }
  
  .css-1rjbbds-singleValue {
    color: var(--font-color) !important;
  }
  
  @media (max-width: 767px) {
    .assign-me-box-col,
    .team-member-table-wrap,
    .profile-main-wraper {
      margin-bottom: 12px;
    }
  
    .assgine-me-list:before {
      display: none;
    }
  
    .assigned-me-box ul li:nth-child(3) .assgine-me-list,
    .observer-main-wrap ul li:not(:last-child) .listing-observer-wrap {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #e6eaf7;
    }
  
    .observer-main-wrap ul li .listing-observer-wrap {
      border-right: none;
    }
  
    .btn-profile-wrap {
      flex-wrap: wrap;
      gap: 10px;
    }
  
    .btn-profile-wrap .edit-btn {
      width: 100%;
    }
  }
  